body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #002A59;
}

.color-bg-main {
  background-color: #002A59 !important;
}
.color-text-main {
  color: #002A59 !important;
}
.color-border-main {
  border-color: #002A59 !important;
}

.color-bg-clear {
  background-color: white !important;
}
.color-text-clear {
  color: white !important;
}
.color-border-clear {
  border-color: white !important;
}

/* primary color */
.color-bg-primary {
  background-color: #004795 !important;
}
.color-text-primary {
  color: #004795 !important;
}
.color-border-primary {
  border-color: #004795 !important;
}
.color-bg-primary-pastel {
  background-color: #799ED2 !important;
}
.color-text-primary-pastel {
  color: #799ED2 !important;
}
.color-border-primary-pastel {
  border-color: #799ED2 !important;
}
.color-bg-primary-light {
  background-color: #ECF1F7 !important;
}
.color-text-primary-light {
  color: #ECF1F7 !important;
}
.color-border-primary-light {
  border-color: #ECF1F7 !important;
}

/* positive color */
.color-bg-positive {
  background-color: #00B5E6 !important;
}
.color-text-positive {
  color: #00B5E6 !important;
}
.color-border-positive {
  border-color: #00B5E6 !important;
}
.color-bg-positive-pastel {
  background-color: #7ED8F0 !important;
}
.color-text-positive-pastel {
  color: #7ED8F0 !important;
}
.color-border-positive-pastel {
  border-color: #7ED8F0 !important;
}
.color-bg-positive-light {
  background-color: #E5F5F9 !important;
}
.color-text-positive-light {
  color: #E5F5F9 !important;
}
.color-border-positive-light {
  border-color: #E5F5F9 !important;
}

/* negative color */
.color-bg-negative {
  background-color: #EB7207 !important;
}
.color-text-negative {
  color: #EB7207 !important;
}
.color-border-negative {
  border-color: #EB7207 !important;
}
.color-bg-negative-pastel {
  background-color: #F8B200 !important;
}
.color-text-negative-pastel {
  color: #F8B200 !important;
}
.color-border-negative-pastel {
  border-color: #F8B200 !important;
}
.color-bg-negative-light {
  background-color: #FFF4E5 !important;
}
.color-text-negative-light {
  color: #FFF4E5 !important;
}
.color-border-negative-light {
  border-color: #FFF4E5 !important;
}

/* secondary color */
.color-bg-secondary {
  background-color: #687173 !important;
}
.color-text-secondary {
  color: #687173 !important;
}
.color-border-secondary {
  border-color: #687173 !important;
}
.color-bg-secondary-pastel {
  background-color: #B3B8B9 !important;
}
.color-text-secondary-pastel {
  color: #B3B8B9 !important;
}
.color-border-secondary-pastel {
  border-color: #B3B8B9 !important;
}
.color-bg-secondary-light {
  background-color: #F6F7F7 !important;
}
.color-text-secondary-light {
  color: #F6F7F7 !important;
}
.color-border-secondary-light {
  border-color: #F6F7F7 !important;
}

/* alert color */
.color-bg-alert {
  background-color: #EB2F4E !important;
}
.color-text-alert {
  color: #EB2F4E !important;
}
.color-border-alert {
  border-color: #EB2F4E !important;
}
.color-bg-alert-pastel {
  background-color: #F093A2 !important;
}
.color-text-alert-pastel {
  color: #F093A2 !important;
}
.color-border-alert-pastel {
  border-color: #F093A2 !important;
}
.color-bg-alert-light {
  background-color: #FDEAED !important;
}
.color-text-alert-light {
  color: #FDEAED !important;
}
.color-border-alert-light {
  border-color: #FDEAED !important;
}

/* support color */
.color-bg-support {
  background-color: #267C46 !important;
}
.color-text-support {
  color: #267C46 !important;
}
.color-border-support {
  border-color: #267C46 !important;
}
.color-bg-support-pastel {
  background-color: #90BBA0 !important;
}
.color-text-support-pastel {
  color: #90BBA0 !important;
}
.color-border-support-pastel {
  border-color: #90BBA0 !important;
}
.color-bg-support-light {
  background-color: #ECF4E3 !important;
}
.color-text-support-light {
  color: #ECF4E3 !important;
}
.color-border-support-light {
  border-color: #ECF4E3 !important;
}

.fw-bold {
  font-weight: bold;
}